import _objectSpread from "D:/wwwroot/\u6709\u53F0\u79D1\u6280\u4F9B\u5E94\u94FE\u534F\u540C\u5E73\u53F0/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import layoutHeaderAside from '@/layout/header-aside';

var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var meta = {
  auth: true
};
export default {
  path: '/order',
  name: 'order',
  meta: meta,
  component: layoutHeaderAside,
  children: [{
    path: 'procure',
    name: 'order-procure-index',
    component: _import('order/procure/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '采购订单'
    }),
    props: true
  }, {
    path: 'consign',
    name: 'order-consign-index',
    component: _import('order/consign/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售订货订单'
    }),
    props: true
  }, {
    path: 'consign/create',
    name: 'order-consign-create',
    component: _import('order/consign/create'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '新增寄售订货订单'
    }),
    props: true
  }, {
    path: 'consign/detail/:id',
    name: 'order-consign-detail',
    component: _import('order/consign/detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售订货订单详情'
    }),
    props: true
  }, {
    path: 'consign/detail/:id/:action',
    name: 'order-consign-detail-receive',
    component: _import('order/consign/detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售订货订单详情'
    }),
    props: true
  }, {
    path: 'consign/return',
    name: 'order-consign-return-index',
    component: _import('order/consign/return/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售退货单'
    }),
    props: true
  }, {
    path: 'consign/return/create',
    name: 'order-consign-return-create',
    component: _import('order/consign/return/create'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '新增寄售退货单'
    }),
    props: true
  }, {
    path: 'consign/return/detail/:id',
    name: 'order-consign-return-detail',
    component: _import('order/consign/return/detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售退货单详情'
    }),
    props: true
  }, {
    path: 'after_sales',
    name: 'order-after-sales-index',
    component: _import('order/after_sales/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '售后订单'
    }),
    props: true
  }]
};