import { request } from '@/api/_service.js';
export function listAgentSellOrder(params) {
  return request({
    url: '/agentSellOrderErpNew/getAgentSellOrderList',
    method: 'get',
    params: params
  });
}
export function listAgentSellOrderExport(params) {
  return request({
    url: '/agentSellOrderErpNew/getAgentSellOrderList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function findAgentSellOrder(params) {
  return request({
    url: '/agentSellOrderErpNew/findAgentSellOrder',
    method: 'get',
    params: params
  });
}
export function createAgentSellOrder(data) {
  return request({
    url: '/agentSellOrderErpNew/createAgentSellOrder',
    method: 'post',
    data: data
  });
}
export function updateAgentSellOrder(data) {
  return request({
    url: '/agentSellOrderErpNew/updateAgentSellOrder',
    method: 'put',
    data: data
  });
}
export function updateAgentSellOrderState(data) {
  return request({
    url: '/agentSellOrderErpNew/updateAgentSellOrderState',
    method: 'put',
    data: data
  });
}
export function deleteAgentSellOrder(data) {
  return request({
    url: '/agentSellOrderErpNew/deleteAgentSellOrder',
    method: 'delete',
    data: data
  });
}
export function listAgentSellOrderDetail(params) {
  return request({
    url: '/agentSellOrderDetailErpNew/getAgentSellOrderDetailList',
    method: 'get',
    params: params
  });
}
export function createAgentSellOrderDetails(data) {
  return request({
    url: '/agentSellOrderDetailErpNew/createAgentSellOrderDetails',
    method: 'post',
    data: data
  });
}
export function updateAgentSellOrderDetail(data) {
  return request({
    url: '/agentSellOrderDetailErpNew/updateAgentSellOrderDetail',
    method: 'put',
    data: data
  });
}
export function findAgentSellOrderDetail(params) {
  return request({
    url: '/agentSellOrderDetailErpNew/findAgentSellOrderDetail',
    method: 'get',
    params: params
  });
}
export function deleteAgentSellOrderDetail(data) {
  return request({
    url: '/agentSellOrderDetailErpNew/deleteAgentSellOrderDetail',
    method: 'delete',
    data: data
  });
}
export function downloadConsignTemplate(params) {
  return request({
    url: '/agentSellOrderDetailErpNew/getAgentSellOrderDetailTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function updateStockoutStatus(data) {
  return request({
    url: '/stockDealer/updateStockoutStatus',
    method: 'put',
    data: data
  });
}