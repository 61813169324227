import { render, staticRenderFns } from "./select.vue?vue&type=template&id=57974abe&scoped=true&"
import script from "./select.vue?vue&type=script&lang=js&"
export * from "./select.vue?vue&type=script&lang=js&"
import style0 from "./select.vue?vue&type=style&index=0&id=57974abe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57974abe",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\wwwroot\\有台科技供应链协同平台\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('57974abe')) {
      api.createRecord('57974abe', component.options)
    } else {
      api.reload('57974abe', component.options)
    }
    module.hot.accept("./select.vue?vue&type=template&id=57974abe&scoped=true&", function () {
      api.rerender('57974abe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/order/consign/return/components/product/select.vue"
export default component.exports