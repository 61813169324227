var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("工作台首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("订货管理")]),
              _c("el-breadcrumb-item", [_vm._v("采购订单")])
            ],
            1
          ),
          _c("div")
        ],
        1
      ),
      [_c("div", [_vm._v(" asdf ")])],
      _c("template", { slot: "footer" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }