export default {
  path: '/order',
  title: '订货管理',
  icon: 'list-alt',
  children: [// {
  //   path: '/order/procure',
  //   title: '采购订单',
  //   icon: 'th-large'
  // },
  {
    path: '/order/consign',
    title: '寄售订货订单',
    icon: 'th-large'
  }, {
    path: '/order/consign/return',
    title: '寄售退货订单',
    icon: 'th-large'
  }]
};